import React, { Component } from "react";
// import { Divider } from 'antd';
import "../App.scss";

class HSSP_230716 extends Component {

  public render() {
    return (
      <div className="Page">
        <h1> Teaching high schoolers about industrial decarbonization </h1>
        <h3> July 27, 2023 </h3>
        <p>
          Expanding on my previous experience with <a href="/blog230320" target="_blank">Spark</a>, I had the opportunity to participate in <a href="https://esp.mit.edu/learn/HSSP/index.html" target="_blank"> MIT ESP HSSP</a>, which is a similar program, but occurs over 6 weekends over the summer, for high schoolers. I'm co-teaching a class called "Saving the World with the Science of Sustainability" with my friends Mike, Simo, and Carlos, who all work in heat transfer at MIT. After Simo gave the introductory lecture on the science of climate change, I gave a lecture on industrial decarbonization. We talked about how industry contributes massively to global carbon emissions, and how steel, cement, and petrochemical production are the largest contributors.
          <br /> <br />
          We then talked specifically about 3 materials, namely steel, cement, and aluminum - we discussed how they are made currently and what the main sources of emissions are, including both direct emissions from the process and indirect emissions from heating and electricity. We did some brainstorming of how they might reduce emissions from these processes, and then talked about some of the current research in the field. For example, we talked about how you can use hydrogen instead of coal to reduce iron ore to iron, how you can exploit the fact that concrete is a CO<sub>2</sub> sink, and how you can use renewables + thermal energy storage to decarbonize industrial heating.
          <br /> <br />
          I thought this would be particularly interesting for high schoolers because I personally didn't learn about industrial decarbonization until graduate school. Yet, many of the principles underlying industrial processes are quite simple, and rely on high school level chemistry (for example, Gibbs free energy). I think it's important for high schoolers to see real-world applications of the abstract things they learn in school. Also, they should realize that electric vehicles and solar cells won't solve the climate crisis alone!
          <br /> <br />
          If you're interested, you can find the slides below:
          <br /> <br />
          <object data='pdfs/L2_IndustrialDecarb.pdf' height="550" width="100%">
                                <p style={{ textAlign: "center" }}>Your browser doesn't support PDFs!<a
                                    href="pdfs/cv.pdf"> Download my CV</a> instead.</p>
                            </object>
        </p>
        
      </div>
    );
  }
}
export default HSSP_230716;
