import React, {Component} from "react";
import "./App.scss";
import {Row, Col} from "antd";

class ResearchPage extends Component {

    public render() {
        return (
            <div className="Page">
                <h1>
                    Research Summary
                </h1>
                <>
                    <Row>
                        <Col span={12}>
                            <img className="researchSummaryImage" src="images/TEGS.png"></img>
                            <p style={{textAlign: "center"}}><a href="/TPV">Thermophotovoltaics</a></p>
                        </Col>
                        <Col span={12}>
                            <img className="researchSummaryImage" src="images/CSP_Gen3.png"></img>
                            <p style={{textAlign: "center"}}><a href="/CSP">Concentrated Solar Power</a></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <img className="researchSummaryImage" src="images/HTVS.png"></img>
                            <p style={{textAlign: "center"}}><a href="/HTVS">Photon Conversion Molecules</a></p>
                        </Col>
                        <Col span={12}>
                            <img className="researchSummaryImage" src="images/LSC.png"></img>
                            <p style={{textAlign: "center"}}><a href="/LSCs">Luminescent Solar Concentrators</a></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <img className="researchSummaryImage" src="images/3d_model.png"></img>
                            <p style={{textAlign: "center"}}><a href="/solarh2"> Solar H2 Production </a></p>
                        </Col>
                        <Col span={12}>
                            <img className="researchSummaryImage" src="images/h2fc.png"></img>
                            <p style={{textAlign: "center"}}><a href="/fuelcell">Fuel Cell Optimization</a></p>
                        </Col>
                    </Row>

                </>
            </div>
        );
    }
}

export default ResearchPage;
