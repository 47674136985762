import React, { Component } from "react";
import "./App.scss";

class FuelCellPage extends Component {

  public render() {
    return (
      <div className="Page">
      <h1> Fuel Cell Optimization </h1>
      <p>
		Project Title: Optimizing the operational efficiency of a PEM fuel cell for use in high-efficiency vehicles
	</p>
	<p>
		Project Dates: August 2017 - May 2019
	</p>
	<p>
		Supervisor: <a href = "https://mems.duke.edu/faculty/nico-hotz" target = "_blank"> Dr. Nico Hotz </a>
	</p>
	<p>
		Collaborators: <a href = "https://gerry-chen.com/" target = "_blank">Gerry Chen</a>, <a href = "https://www.linkedin.com/in/patrick-grady-6a523886/" target="_blank">Patrick Grady</a>, <a href = "https://mems.duke.edu/faculty/josiah-knight" target="_blank">Dr. Josiah Knight</a>
	</p>
	<p>
		Location: <a href="http://www.duke-ev.org/" target="_blank"> Duke Electric Vehicles Laboratory</a>, Duke University
	</p>

	<p>
		Project Summary:
	</p>
	<p>
		Proton exchange membrane (PEM) hydrogen fuel cells are growing in popularity as an energy conversion technology, generating electricity from chemical energy. One barrier to large-scale deployment is efficiency, as most PEM fuel cells only achieve ~40% at nominal operating conditions. This study aims to increase this efficiency by considering several operating variables (cathode and anode side gas pressures, gas and membrane temperature/humidity, load point, fan speed, short circuiting, anode purging) and determining their effects on maximizing experimental efficiency of a commercially available PEM fuel cell. While previous studies focus on each specific variable described above, this work is novel in considering the effects of all variables. A robust gradient descent optimization is conducted to solve the multidimensional problem of maximizing efficiency. The overall system efficiency is considered paramount, so all power input is considered (i.e. fan power) and no external heating is used. As a primarily experimental study, we hope to spur further data acquisition in fuel cell characterization as a way to validate modeled systems. The study was successful in increasing the nominal 40% datasheet efficiency to 61.1% in bench testing.
	</p>
  <p> See <a href="/publications"> Publications </a> for the paper published on this research. </p>
	<img className = "researchImage" src = "images/h2fc.png"></img>
      </div>
    );
  }
}
export default FuelCellPage;
