import React, { Component } from "react";
// import { Divider } from 'antd';
import "../App.scss";

class Spark_240318 extends Component {

  public render() {
    return (
      <div className="Page">
        <h1> Teaching middle schoolers about extreme engineering </h1>
        <h3> March 18, 2024 </h3>
        <p>
                I've been having a lot of fun teaching for MIT ESP, so I taught again for Spark this year with my labmate Mike. We shifted focus a little bit and taught a class on extreme engineering. We talked about how engineers can design things to withstand extreme conditions, such as high temperatures, high stresses, and harsh environments. Check out the slides below!
                <br /> <br />
          <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vSLZ-MbMaj3m9Oy8uHrL901yQiPmxSGBoYAeIidNnQVh84ujHoAwcvoR-e0NOXsyteulkkC3H--dM4b/embed?start=false&loop=false&delayms=3000" width="960" height="569" ></iframe>
        </p>
        
      </div>
    );
  }
}
export default Spark_240318;
