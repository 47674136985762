import { Layout } from "antd";
import Menu from "antd/es/menu";
import React, { Component } from "react";
import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import {
  MailOutlined,
  GithubOutlined,
  LinkedinOutlined,
  GoogleOutlined,
  FacebookOutlined,
  TwitterOutlined,
  YoutubeOutlined,
  IdcardOutlined,
  PhoneOutlined,
  MessageOutlined
} from '@ant-design/icons';
import { Row, Col } from "antd";
// import ReactGA from 'react-ga';
import PublicationsPage from "./PublicationsPage";
import NamePage from "./NamePage";
import ResearchPage from "./ResearchPage"
import SolarH2Page from "./SolarH2Page"
import CSPPage from "./CSPPage"
import LSCPage from "./LSCPage"
import HTVSPage from "./HTVSPage"
import TPVPage from "./TPVPage"
import FuelCellPage from "./FuelCellPage"
import BlogPage from "./BlogPage"
import Spark_230320 from "./Blog/Spark_230320"
import HSSP_230716 from "./Blog/HSSP_230716"
import Spark_240318 from "./Blog/Spark_240318"
import EGR201_Duke from "./Blog/EGR201_Duke";
import NewsPage from "./NewsPage"

import "./App.scss";

const { Header, Content, Footer } = Layout;

// const trackingId = 'UA-164337188-1'; // Replace with your Google Analytics tracking ID
// ReactGA.initialize(trackingId);
// ReactGA.pageview('/homepage');

class App extends Component {

  public handleClick = (e: any) => {
    this.setState({
      current: e.key,
    });
  }

  public render() {
    return (

      <Router forceRefresh={true}>
        <Layout>
          <Header style={{ position: "fixed", zIndex: 1, width: "100%" }}>
            {/* <svg width="100vw" height="50">
            <rect width="25vw" height="5" />
            <rect width="25vw" height ="20"/>
          </svg>*/}
            <Menu onClick={this.handleClick} selectedKeys=
              {[window.location.pathname === "/" ? "name" : window.location.pathname.split("/").join("")]}
              mode="horizontal" className="Menu" style={{ lineHeight: "40px" }}>
              <Menu.Item key="name">
                <Link to="/" >Home</Link>
              </Menu.Item>
              <Menu.Item key="research">
                <Link to="/research">Research</Link>
              </Menu.Item>
              
              {/* <Menu.SubMenu key="solarh2" title="Research">
                <Menu.Item key="research">
                  <Link to="/research">Summary</Link>
                </Menu.Item>
                <Menu.Item key="TPV">
                  <Link to="/TPV">Thermophotovoltaics</Link>
                </Menu.Item>
                <Menu.Item key="HTVS">
                  <Link to="/HTVS">Photon Conversion Molecules</Link>
                </Menu.Item>
                <Menu.Item key="LSCs">
                  <Link to="/LSCs">Luminescent Solar Concentrators</Link>
                </Menu.Item>
                <Menu.Item key="CSP">
                  <Link to="/CSP">Concentrated Solar Power</Link>
                </Menu.Item>
                <Menu.Item key="fuelcell">
                  <Link to="/fuelcell">Fuel Cell Optimization</Link>
                </Menu.Item>
                <Menu.Item key="solarh2">
                  <Link to="/solarh2">Solar Hydrogen Production</Link>
                </Menu.Item>
              </Menu.SubMenu> */}
              <Menu.Item key="publications">
                <Link to="/publications">Publications</Link>
              </Menu.Item>
              <Menu.Item key="news">
                <Link to="/news">News and Media</Link>
              </Menu.Item>
              <Menu.Item key="blog">
                <Link to="/blog">Teaching</Link>
              </Menu.Item>
            </Menu>
          </Header>
          <Content style={{ minHeight: "calc(100vh - 110px)" }}>
            <Route exact path="/" component={NamePage} />
            <Route path="/research" component={ResearchPage} />
            <Route path="/solarh2" component={SolarH2Page} />
            <Route path="/fuelcell" component={FuelCellPage} />
            <Route path="/CSP" component={CSPPage} />
            <Route path="/LSCs" component={LSCPage} />
            <Route path="/HTVS" component={HTVSPage} />
            <Route path="/TPV" component={TPVPage} />
            <Route path="/publications" component={PublicationsPage} />
            <Route path="/news" component={NewsPage} />
            <Route path="/blog" component={BlogPage} />
            <Route path="/blog230320" component={Spark_230320} />
            <Route path="/blog230716" component={HSSP_230716} />
            <Route path="/blog240318" component={Spark_240318} />
            <Route path="/blog190500" component={EGR201_Duke} />
          </Content>
          <Footer style={{ textAlign: "center" }}>
            <span><a href="https://scholar.google.com/citations?hl=en&user=5zXE7AEAAAAJ&view_op=list_works&sortby=pubdate" target="_blank"><GoogleOutlined style={{ fontSize: '24px' }} /> </a></span>
            <span><a href="https://orcid.org/0000-0002-0402-9930" target="_blank"><IdcardOutlined style={{ fontSize: '24px' }} /> </a></span>
            <span><a href="https://github.com/shomikverma" target="_blank"><GithubOutlined style={{ fontSize: '24px' }} /> </a></span>
            <br></br>
            <br></br>
            <span><a href="mailto:shomik.verma@gmail.com" target="_blank"><MailOutlined style={{ fontSize: '24px' }} /> </a></span>
            <span><a href="sms:7135780577" target="_blank"><MessageOutlined style={{ fontSize: '24px' }} /> </a></span>
            <span><a href="tel:7135780577" target="_blank"><PhoneOutlined style={{ fontSize: '24px' }} /> </a></span>
            <span><a href="https://www.linkedin.com/in/shomikverma/" target="_blank"><LinkedinOutlined style={{ fontSize: '24px' }} /> </a></span>
            <span><a href="https://twitter.com/shomikthemoney" target="_blank"><TwitterOutlined style={{ fontSize: '24px' }} /> </a></span>
            <span><a href="https://www.facebook.com/shomik.verma" target="_blank"><FacebookOutlined style={{ fontSize: '24px' }} /> </a></span>
            <span><a href="https://www.youtube.com/channel/UCRvtMu0z5HiFYdNw0kuZdbA" target="_blank"><YoutubeOutlined style={{ fontSize: '24px' }} /> </a></span>
            <>
              <Row className="footerRow">
                <Col span={(window.innerWidth > 500) ? 2 : 0}>
                </Col>
                {/* <Col span={(window.innerWidth > 500) ? 10 : 12} >
                  <p style={{ textAlign: "left" }}> Site Map </p>
                  <p style={{ textAlign: "left", marginBottom: "0px" }}> <a href="/"> Home </a> </p>
                  <p style={{ textAlign: "left", marginTop: "0px", marginBottom: "0px" }}> <a href="/research"> Research </a></p>
                  <p style={{ textAlign: "left", marginTop: "0px", marginBottom: "0px" }}> <a href="/publications">Publications </a></p>
                  <p style={{ textAlign: "left", marginTop: "0px", marginBottom: "0px" }}> <a href="/news">News </a></p>
                  <p style={{ textAlign: "left", marginTop: "0px", marginBottom: "0px" }}> <a href="/blog">Blog </a></p>
                </Col>
                <Col span={(window.innerWidth > 500) ? 10 : 12}>
                  <p style={{ textAlign: "right" }}> Contact Me </p>
                  <p style={{ textAlign: "right", marginTop: "16px", marginBottom: "0px" }}> (713)578-O577</p>
                  <p style={{ textAlign: "right", marginTop: "0px", marginBottom: "0px" }}> <a href="mailto:shomik.verma@gmail.com" target="_blank">shomik.verma@gmail.com</a></p>
                  <p style={{ textAlign: "right", marginTop: "0px", marginBottom: "36px" }}> Or at the above sites!</p>
                </Col> */}
                <Col span={(window.innerWidth > 500) ? 2 : 0}>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <p style={{ textAlign: "center" }}>© 2024 Shomik Verma.</p><p style={{ marginTop: "0px", color: "gray" }}> Built with Kyle Swanson, Zack Holbrook, and Jesse Michel's <a href="https://github.com/swansonk14/PersonalWebsite" target="_blank">Source Code</a></p>
                </Col>
              </Row>
            </>
          </Footer>
        </Layout>
      </Router>
    );
  }
}

export default App;
