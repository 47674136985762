import React, { Component } from "react";
// import { Divider } from 'antd';
import "../App.scss";

class Spark_230320 extends Component {

  public render() {
    return (
      <div className="Page">
        <h1> Teaching middle schoolers about atomistic science </h1>
        <h3> March 20, 2023 </h3>
        <p>
          This past weekend I had the opportunity to teach for <a href="https://esp.mit.edu/learn/Spark/index.html" target="_blank"> MIT ESP Spark</a>, a program that brings middle schoolers to MIT to learn about a wide variety of topics including engineering, humanities, and pop culture. My labmate Mike and I taught a class on atomistic science, which is the study of matter at the level of individual atoms. We taught the students about what an atom is, how they interact, and how we can model these interactions, starting from the ball and spring model and then the Lennard-Jones potential. We connected these concepts to macro-scale properties such as thermal expansion, thermal conductivity, and mechanics of materials. 
          <br /> <br />
          The students were very engaged and asked a lot of great questions. For example, we were talking about why water evaporates at room temperature, and one of them made the connection to a humidifier that they had at home and asked if it works because of the same mechanism. I was very pleasantly surprised by how much the students already knew, they seemed to have a firm grasp of chemistry and some of them even know about concepts such as vapor pressure. However, very few of them knew about physics, even fundamental concepts such as Newton's second law. I wonder why schools prioritize chemistry and biology over physics, especially because physics can often be more intuitive and easier to understand than chemistry. Maybe because it is requires more math? I think that we should at least teach the concepts behind physics earlier, so students can start to develop some physical intuition before delving into the math.
          <br /> <br />
          Overall, I really enjoyed teaching them about the science and engineering behind materials, and I hope that they learned something new about the world around them. I also had a lot of fun planning the class and figuring out how to explain some complicated concepts clearly. I'm looking forward to teaching again next year!
          <br /> <br />
          If you're interested, you can find the slides below. I might record the lecture and post it here later. Thanks to my labmate Yang for helping with the MD simulations of heat diffusion!
          <br /> <br />
          <iframe src="https://mitprod-my.sharepoint.com/:p:/g/personal/skverma_mit_edu/EV7Sit6MpvNKvXZ5BYo4VA8BwEo0oJHtyhzk7U81PNFy_g?e=dpkgjV&amp;action=embedview&amp;wdAr=1.7777777777777777" width="100%" height="550px">This is an embedded <a target="_blank" href="https://office.com">Microsoft Office</a> presentation, powered by <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>
        </p>
        
      </div>
    );
  }
}
export default Spark_230320;
