import React, { Component } from "react";
import "./App.scss";

class NewsPage extends Component {

	public render() {
		return (
			<div className="Page">
				<h1>
					News and Media
				</h1>
				<p> June 2023, Global Indian: <a href="https://www.globalindian.com/youth/story/global-indian-exclusive/meet-shomik-verma-the-rising-star-researcher-leading-the-way-in-clean-energy/" target="_blank">Meet Shomik Verma, the rising star researcher leading the way in clean energy</a></p>
				<p> April 2023, MIT News: <a href="https://news.mit.edu/2023/paul-daisy-soros-fellowships-new-americans-0425" target="_blank">Six from MIT awarded 2023 Paul and Daisy Soros Fellowships for New Americans</a></p>
				<p> April 2023, Forbes: <a href="https://www.forbes.com/sites/michaeltnietzel/2023/04/25/the-paul--daisy-soros-fellowships-for-new-americans-for-2023-are-announced" target="_blank">The 2023 Paul & Daisy Soros Fellowships For New Americans Are Announced</a> </p>
				<p> March 2023, ARPA-E: <a href="https://www.youtube.com/watch?v=lXCBzZ__x1c" target="__blank">Student PITCHES Winner Shomik Verma</a>, <a href="https://arpa-e.energy.gov/news-and-media/blog-posts/wrapping-2023-arpa-e-energy-innovation-summit" target="__blank">Wrapping Up the 2023 ARPA-E Energy Innovation Summit</a></p>
				<p> September 2021, GWR: <a href="https://www.guinnessworldrecords.com/products/books/guinness-world-records-2022" target="__blank">Guinness World Records 2022</a>, <a href="https://www.guinnessworldrecords.com/world-records/399938-most-fuel-efficient-electric-vehicle" target="__blank"> Most Efficient Electric Vehicle (Prototype) </a> </p>
				<p> September 2019, GWR: <a href="https://www.guinnessworldrecords.com/products/books/guinness-world-records-2020/" target="__blank">Guinness World Records 2020</a>, <a href="https://www.guinnessworldrecords.com/world-records/most-fuel-efficient-vehicle" target="__blank"> Most Fuel-Efficient Vehicle (Prototype) </a> </p>
				<p> September 2019, Promazo: <a href="https://students.promazo.com/work" target="__blank">40 Most Interesting Students</a> </p>
				<p> July 2019, Pratt: <a href="https://pratt.duke.edu/about/news/duke-student-team-wins-second-guinness-world-record-vehicle-efficiency" target="__blank">Duke Student Team Wins Second Guinness World Record for Vehicle Efficiency</a> </p>
				<p> May 2019, Duke Today: <a href="https://today.duke.edu/2019/05/class-2019-eight-students-who-changed-duke-and-themselves" target="__blank">Class of 2019: Eight Students who Changed Duke... and Themselves</a> </p>
				<p> February 2019, Energy Initiative: <a href="https://www.youtube.com/watch?v=EJKcyCK_ZAs" target="__blank"> Energy Student Profile: Shomik Verma (E'19)</a> </p>
				<p>December 2018, Pratt:&nbsp;<a href="https://pratt.duke.edu/about/news/verma-marshall" target="__blank">Verma Wins 2019 Marshall Scholarship</a></p>
				<p>December 2018, Duke Today:&nbsp;<a href="https://today.duke.edu/2018/12/two-duke-students-win-marshall-scholarships" target="__blank">Two Duke Students Win Marshall Scholarships</a></p>
				<p>October 2018, COMSOL:&nbsp;<a href="https://www.comsol.com/blogs/award-winning-papers-and-posters-comsol-conference-2018-boston/" target="__blank">Award-Winning Papers and Posters: COMSOL Conference 2018 Boston</a> and associated <a href="https://www.linkedin.com/posts/comsol-inc-_the-comsol-conference-is-a-great-chance-to-activity-6567810127976554496-Lvb9/" target="__blank"> video </a></p>
				<p>August 2018, Clean Technica:&nbsp;<a href="https://cleantechnica.com/2018/08/20/duke-university-students-set-world-record-for-hydrogen-fuel-cell-economy-14573-miles-per-gallon/" target="__blank">Duke University Students Set World Record For Hydrogen Fuel Cell Fuel Economy &mdash; 14,573 Miles Per Gallon</a></p>
				<p>August 2018, Duke Energy Initiative (video):&nbsp;<a href="https://www.youtube.com/watch?v=OkNlXpRie2M" target="__blank">Duke Electric Vehicles Team Breaks Guinness World Record for Fuel Efficiency</a></p>
				<p>August 2018, Duke Chronicle:&nbsp;<a href="http://www.dukechronicle.com/article/2018/08/the-mountain-no-one-thought-they-could-climb-duke-team-sets-world-record-for-most-efficient-vehicle" target="__blank">&lsquo;The mountain no one thought they could climb&rsquo;: Duke team sets world record for most efficient vehicle</a></p>
				<p>August 2018, Pratt:&nbsp;<a href="https://pratt.duke.edu/about/news/duke-students-break-guinness-world-record-fuel-efficiency" target="__blank">Duke Students Break Guinness World Record for Fuel Efficiency</a></p>
				<p>July 2018, Killer Innovations (podcast):&nbsp;<a href="http://killerinnovations.com/competing-for-fuel-efficiency-innovation/" target="__blank">Competing for Fuel Efficiency Innovation</a></p>
				<p>July 2018, Pratt:&nbsp;<a href="https://pratt.duke.edu/about/news/duke-engineers-score-triple-win-shell-eco-marathon-americas-2018" target="__blank">Duke Engineers Score a Triple Win at Shell Eco-marathon Americas 2018</a></p>
				<p>April 2018, Pratt:&nbsp;<a href="https://pratt.duke.edu/about/news/shomik-verma-awarded-udall-scholarship" target="__blank">Shomik Verma Awarded Udall Scholarship</a></p>
				<p>April 2018, Duke Today:&nbsp;<a href="https://today.duke.edu/2018/04/three-duke-students-named-udall-scholars" target="__blank">Three Duke Students Named Udall Scholars</a></p>
				<p>April 2018, Duke Mathematics:&nbsp;<a href="https://math.duke.edu/news/2018-barry-m-goldwater-scholar-shomik-verma" target="__blank">2018 Barry M. Goldwater Scholar: Shomik Verma</a></p>
				<p>April 2018, Duke Today:&nbsp;<a href="https://today.duke.edu/2018/04/two-duke-juniors-one-sophomore-named-goldwater-scholars" target="__blank">Two Duke juniors, one sophomore named Goldwater Scholars</a></p>
				<p>November 2017, Duke Research Blog:&nbsp;<a href="https://researchblog.duke.edu/2017/11/13/dukes-solar-benches-can-charge-your-phone/" target="__blank">Duke&rsquo;s Solar Benches Can Charge Your Phone</a></p>
				<p>April 2017, Energy Initiative:&nbsp;<a href="https://energy.duke.edu/news/duke-students-spend-spring-break-installing-solar-panels-fresno" target="__blank">Duke students spend spring break installing solar panels in Fresno</a></p>
				<p>March 2017, ABC30:&nbsp;<a href="http://abc30.com/amp/society/students-spend-spring-break-installing-solar-panels-for-fresno-families/1806361/" target="__blank">Students spend Spring Break installing solar panels for Fresno families</a></p>
			</div>
		);
	}
}
export default NewsPage;
