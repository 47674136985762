import React, {Component} from "react";
import "./App.scss";

class ResearchPage extends Component {

    public render() {
        return (
            <div className="Page">
                <h1>
                    Thermophotovoltaics
                </h1>
                <p>
                    Project Title: Multi-scale modeling and design of efficient thermophotovoltaic systems
                </p>
                <p>
                    Project Dates: September 2021 - Present
                </p>
                <p>
                    Supervisors: <a href="https://meche.mit.edu/people/faculty/ase@mit.edu" target="_blank"> Prof.
                    Asegun Henry</a>
                </p>
                <p>
                    Collaborators: Alina LaPotin, Colin Kelsall, Kyle Buznitsky
                </p>
                <p>
                    Location: <a href="https://ase.mit.edu/" target="_blank">Atomistic Simulation & Energy Research
                    Group</a>, Massachusetts Institute of Technology
                </p>
                <h2> Project Summary: </h2>
                <p>
                    Instead of capturing light from the sun like traditional photovoltaics (PV), thermophotovoltaics
                    (TPV) absorb light emitting from a heat source. This improves reliability as TPV can operate at any
                    time of day. Further, while solar PV cells cannot absorb light below their band gap, TPV circumvents
                    this issue by reflecting sub-bandgap light back towards the thermal emitter, recycling their energy
                    instead of losing it. Thus, TPV has the potential of vastly outperforming solar PV technology.
                </p>
                <p>
                    There are a couple of fundamental questions that arise from above. First, how do we ensure all of
                    the sub-bandgap light is reflected? Some of our collaborators are working on designing
                    high-reflectivity back-surface reflectors. However, there is also some parasitic losses within the
                    PV cell itself. The first part of this project investigates the impact of free-carrier absorption on sub-bandgap absorption using atomistic
                    simulation techniques. Given a TPV cell architecture, the goal is to predict the influence of each layer of the highly-doped tunnel junction on overall sub-bandgap absorption, which helps guide optimization of cell
                    design.
                </p>
                <p>
                    The second question is how to generate heat for the TPV cell. Traditionally, the Atomistic
                    Simulation & Energy lab has combined a thermal energy grid storage (TEGS) system with TPV. TEGS acts
                    as a thermal battery, storing heat generated through resistance heating of a heat-transfer fluid.
                    Another possible heat generation mechanism is through combustion. The second part of this project
                    focuses on efficient combustor design, using computational fluid dynamics (CFD) with chemical
                    reaction kinetics to model conversion of chemical energy into heat. The goal is to design a
                    combustor with low heat loss and high efficiency, to act as a cheaper and potentially more efficient
                    alternative to fuel cells.
                </p>


            </div>
        );
    }
}

export default ResearchPage;
