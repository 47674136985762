import React, { Component } from "react";
import "./App.scss";
import MathJax from "react-mathjax"

class CSPPage extends Component {

  public render() {
    return (
      <MathJax.Provider>
      <div className="Page">
      <h1>
		Concentrated Solar Power (CSP)
	</h1>
	<p>
		Project Title: High temperature emissometry of an absorptive ceramic particle bed
	</p>
	<p>
		Project Dates: June - August 2019
	</p>
	<p>
		Supervisor: <a href = "http://me.gatech.edu/faculty/zhang" target = "_blank"> Dr. Zhuomin Zhang </a>
	</p>
	<p>
		Collaborators: Chuyang Chen
	</p>
	<p>
		Location: <a href = "http://zhang-nano.gatech.edu/" target = "_blank"> Nanoscale Thermal Radiation Laboratory</a>, Georgia Institute of Technology
	</p>

	<p>Project Summary:</p>
<p>Concentrated solar power (CSP) has garnered significant attention in recent years due to the high efficiency of plants and inherent thermal storage. However, conventional CSP plants, using water or molten salts as the working fluid, suffer from some fundamental flaws. In an effort to improve these systems, the current study investigates solid-state particles as the working fluid, in which a falling curtain of such particles act as the receiver of solar irradiance. The emissivity of these particles in the mid-IR region is documented at high temperature (400-500C), and extensive heat transfer analysis and modeling is conducted to identify methodologies of reaching higher temperatures in future work. This study serves as a useful preliminary baseline for high-temperature emissometry of a particle bed, with promising initial results that qualitatively match indirectly measured emissivity at room temperature.
</p>
<hr></hr>
<h2>Introduction</h2>
<p>The basic function of concentrated solar power (CSP) power plants is the same as conventional, fossil fuel-based plants: heat up water to generate steam, and run the steam through a turbine to generate electricity. However, instead of burning coal to provide the heat necessary to boil water, CSP plants use solar power as an input. Since normal incident sunlight cannot provide enough power intensity to be valuable for heating, CSP plants feature solar concentrators, in the form of flat mirrors, lenses, parabolic troughs, or other objects to concentrate sunlight onto a central receiver that achieves high solar flux. This allows multiplication of normal solar incidence anywhere from 10 to 500 times, depending on the concentration methodology used.</p>
<p>CSP plants of the past most commonly featured water or molten salts as their working fluid in the central receiver. However, these fluids have several non-idealities, for example breaking down at low temperatures (>700C), freezing at high temperatures (~200C), inadequate storage properties, and low efficiencies. Therefore, significant research is being conducted nationwide to develop the next generation of CSP technologies, using either solids, liquids, or gases as the primary working fluid. This study focuses on a solid-state CSP receiver, using a falling curtain of particles as the working fluid. </p>

<img className = "researchImage" src = "images/CSP_Gen3.png"></img>
<p style={{textAlign:"center"}}>Figure 1: Gen3 CSP pilot plant at Sandia National Labs</p>

<p>The specific particles chosen are the CARBOPROP series produced by CARBO Ceramics, Inc. These particles are typically used as proppants in the oil and gas industry for hydraulic fracturing applications. Due to their chemical composition, they also happen to have ideal absorption properties for CSP applications. This study will focus on the CP 40/100 variant of the CARBOPROP series, with material composition and some physical properties reported in previous works.
</p>
<h2>Background</h2>
<p>This work will focus on evaluating the radiative properties of CP 40/100. Proper design of falling particle receiver must include rigorous heat transfer modeling to define operational parameters and evaluate system efficiency. Such analysis requires accurate data about material properties of these particles. While the conductive and convective properties of the particle curtain are known, the radiative properties must be investigated further. Radiative properties are critical as they define how much heat the particle curtain absorbs or emits. High absorptivity in the visible is important for efficient operation of CSP receiver. This has been characterized fairly extensively by researchers in the past, to prove the efficacy of CP 40/100 as a working fluid.</p>
<p>However, the emissive properties have not been fully investigated yet, especially in the mid-IR region. Emissivity of particles is an important factor in CSP operation, since high emissivity in the IR means high losses of absorbed radiation. Therefore, knowing the emissive properties is useful in modeling, and will drive design changes to limit heat losses.</p>
<p>Previous work in our lab investigated the emissivity of the particles at room temperature. Instead of directly measuring emittance, the previous study indirectly calculated emissivity by measuring the reflectivity of the sample and using the formula <MathJax.Node inline formula={'\\varepsilon_{\\lambda} = \\alpha_{\\lambda} = 1-\\rho_{\\lambda}'} /> to calculate spectral emissivity. Note that the formula assumes optical opacity, which was proven in the study by measuring transmissivity and showing it is less than 0.5%. The formula also uses Kirchhoff's Law where spectral absorptivity equals spectral emissivity. The previous work resulted in room temperature emissivity measurements.</p>
<p>While this is useful data for reference, there are a few drawbacks: the emissivity is indirectly calculated, and the emissivity of the sample may change at the high operating temperatures (>700C). Therefore, this study aims to directly measure particle bed emissivity at high temperatures. This is done by comparing the spectral emittance of the particle bed at a high temperature, and comparing it to the spectral emittance of a blackbody.</p>

<p>
<span style = {{float:"right"}}>
(1)
</span>
<MathJax.Node  formula={'\\varepsilon_{\\lambda} = \\dfrac{M_{e,\\lambda}}{M_{e,\\lambda}^o}'}/>
</p>

<p>Where <MathJax.Node inline formula={'\\varepsilon_\\lambda'} /> is the spectral hemispherical emissivity, <MathJax.Node inline formula={'M_{e,\\lambda}'} /> is the spectral emittance of the surface, and <MathJax.Node inline formula={'M_{e,\\lambda}^o'} /> is the spectral emittance of a blackbody at the same temperature as the surface. The following sections provide details about the methodology and results of this study.</p>
<h2>Experimental Setup</h2>
<p>The primary components of the experimental setup include the particle bed, blackbody source, FTIR spectrometer, and optical instruments. </p>
<p>The particle bed is composed of CP 40/100 particles, 2mm deep and housed in a custom machined copper crucible. The crucible has a built-in thermocouple at its base for temperature measurements, and an additional thermocouple may be placed at the particle surface for particle surface temperature measurements. If the additional thermocouple was used, it was placed away from the spot of emitted radiation detected by the FTIR, as determine by an LED lamp. A schematic of the particle bed layout is shown below.</p>

<img className = "researchImage"  src = "images/cruc_schematic.png" ></img>

<p style={{textAlign:"center"}}>Figure 2: Schematic of particle bed layout, showing crucible and thermocouples</p>

<p>The entire particle bed + crucible assembly is placed in the 1" diameter cavity of the MHI Gen2 Heat Pad, shown below. The heat pad was able to heat the crucible to a maximum of 800C in vacuum while drawing 14.3 A and 4V. A LabView program was develop to accurately control the temperature of the crucible by controlling the current supplied to the heat pad, and was able to achieve accuracy of &plusmn; 0.1C for any setpoint temperature desired.</p>

<img className = "researchImage" src = "images/heatpad.png"></img>

<p style={{textAlign:"center"}}>Figure 3: MHI Gen2 Heat Pad with 1" diameter cavity used for particle bed heating</p>

<p>The heat pad, crucible, and particle bed assembly was then placed in a vacuum chamber for heating. The vacuum chamber was custom machined and featured a variety of feedthroughs for power cables and thermocouple cables, and a port for the vacuum pump connection. The chamber also had a Zinc Selenide (ZnSe) vacuum compatible window on the side to allow the emitted radiation from the sample to escape. The heat pad was laid horizontally and a gold 45&deg; flat mirror was used to reflect the vertically emitted radiation back to the horizontal direction.</p>
<p>The blackbody source was a Mikron M360 blackbody calibration source. To ensure optical equivalency, a ZnSe window was also placed in front of the blackbody source, and a flat 45&deg; gold mirror was also used to reflect emitted radiation. The blackbody came with a controller with 0.2% accuracy and &plusmn;1C source nonuniformity.</p>
<p>An ABB FTLA2000 FTIR spectrometer was used to analyze the spectral intensity of radiated light. The FTIR requires perfectly collimated light for analysis, so a carefully designed and calibrated optical setup was used.</p>
<p> After the radiated light hits the 45&deg; flat gold mirror and passes through the ZnSe window, it hits an off-axis gold ellipsoidal mirror to change the focal point of the light, after which it hits an off-axis parabolic mirror to convert the incident light to a parallel beam.</p>

<h2>Experimental Procedure</h2>
<p>First, to characterize blackbody emittance, a series of spectral emittance measurements were taken of the blackbody, specifically at 400C, 450C, and 500C. These provided a useful reference against which the particle emittance spectra could be compared.</p>
<p>Two types of measurements were taken of particle emissivity &ndash; not under vacuum and under vacuum. For the non-vacuum case, the crucible was only able to be heated to a maximum of ~550C, due to the extensive conductive and convective losses. For the vacuum case, the crucible was able to be heated to a maximum of 750C, since the only heat losses were due to radiation. However, in the vacuum case, there is a significant temperature difference between crucible base temperature and particle surface temperature. This is likely due to the lowering of effective thermal conductivity of the particle bed in vacuum, since the air providing a conductive heat transfer pathway has been evacuated, leaving radiation as the only heat transfer mechanism.</p>
<p>The following plot quantitatively shows the temperature difference between particle surface temperature and crucible temperature as a function of crucible temperature. </p>

<img className = "researchImage" src = "images/tempdiff.png" ></img>

<p style={{textAlign:"center"}}>Figure 4: Temperature difference vs. crucible temperature</p>

<p> The particle surface temperature was set to various temperatures between 400 and 500C. At each temperature, once the particle bed reached steady state, an emittance spectrum was measured by the FTIR. This emittance spectrum was compared against the blackbody emittance, as discussed earlier. </p>

<h2>Results</h2>

<p> Plots for spectral emissivity in the non-vacuum and vacuum cases of particle emissometry were obtained, but cannot be presented here yet since the work is currently unpublished. The emissivity was calculated by dividing the particle spectral emittance by the blackbody spectral emittance, as shown in Equation 1.
</p>
<p>
These plots were promising as they showed consistency across various temperature regions. However, ideally we would like to reach higher temperatures to more accurately mimic the operating conditions of a CSP plant (&gt;700C). This is difficult to do for 2 main reasons: under non vacuum, the crucible fails to reach &gt;550C, and under vacuum, while the crucible reaches high temperatures, the temperature difference between crucible and particle surface is significant. To better understand the problems with this system, we must first get a more accurate reading of temperature, since the thermocouples may suffer from problems with contact resistance and therefore under-report temperature.</p>

<h2>Heat Transfer Modeling</h2>

<p>A 2D axisymmetric heat transfer model was created in COMSOL. The model featured a copper crucible and a particle bed modeled as a porous media of 74% air and 26% particle (based on the highest density possible for spherical packing).</p>

<p>A temperature boundary condition was set at the bottom of the copper crucible, equal to the crucible temperature determined by emissometry. Diffuse radiation was defined for the top of the particle bed with an emissivity of 0.8. Auxiliary heat transfer losses were also included: diffuse radiation off the sides of the copper, convective heat transfer off the top and sides of the crucible (non vac case), and thermal contact resistance between the particle bed and the crucible. Then, the thermal conductivity of the particle bed was varied until the particle surface temperature matched the experimental data.</p>

<p>The predicted surface temperatures match the experimental data quite well, and the thermal conductivity of the bed is within reasonable values. According to this simulation, the major culprit for temperature difference is the contact resistance between the copper and the particle bed. The key to minimizing the temperature difference and therefore achieving higher particle bed surface temperature is minimizing the contact resistance, whether by improving the crucible surface, or by other means. </p>

<h2>Conclusions</h2>

<p>This work measures the radiative properties of the CP40/100 ceramic particle. Specifically, the emissivity of the particles in the mid-IR region is measured. The results show consistent measurements between 400 and 500C, as well as a qualitative match with room-temperature emissometry results. While the initial results are promising, further improvements and calibration are required, especially in order to reach 800C. Future work in improving the crucible setup, using a solid slab of particles, or using a new heat pad may help achieve the high temperatures. Regardless, this study has proven useful in measuring as a preliminary, baseline high-temperature emissivity.</p>
      </div>
      </MathJax.Provider>
    );
  }
}
export default CSPPage;
