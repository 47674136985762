import React, { Component } from "react";
import { Row, Col } from "antd";
import "./App.scss";
// import PDFViewer from 'mgr-pdf-viewer-react'
// import {PDFReader} from 'react-read-pdf'
type MyProps = {};
type MyState = { numPages: number, pageNumber: number };


class NamePage extends Component<MyProps, MyState> {

    public render() {
        return (
            <div className="MainPage">
                <>
                    <Row>
                        <Col span={(window.innerWidth > 500) ? 15 : 24} offset={(window.innerWidth > 500) ? 7 : 0}>
                            <h1 className="nameTitle" style={{ textAlign: "center" }}>Exploring the Future of Energy</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={(window.innerWidth > 500) ? 1 : 0}>
                        </Col>
                        <Col className="imageCol" span={(window.innerWidth > 500) ? 5 : 24}>
                            <img className="selfImage" src="images/shomik_verma2.png"></img>
                            <p style={{ margin: "0px", textAlign: 'center' }}> Shomik Verma </p>
                            <p style={{ margin: "0px", textAlign: 'center' }}> MechE PhD MIT (current) </p>
                            <p style={{ margin: "0px", textAlign: 'center' }}> MatSci MPhil Imperial '21 </p>
                            <p style={{ margin: "0px", textAlign: 'center' }}> MatSci MPhil Cambridge '20 </p>
                            <p style={{ margin: "0px", textAlign: 'center' }}> MechE BSE Duke '19 </p>
                            <br></br>
                        </Col>
                        <Col span={(window.innerWidth > 500) ? 15 : 24} offset={(window.innerWidth > 500) ? 1 : 0}>
                            <p> I’m Shomik Verma, from Houston, Texas. I'm currently a Mechanical Engineering PhD
                                student at the Massachusetts Institute of Technology, working with
                                <a href="https://meche.mit.edu/people/faculty/ase@mit.edu" target="_blank"> Prof. Asegun
                                    Henry </a>
                                in the <a href="https://ase.mit.edu/" target="_blank">Atomistic Simulation & Energy
                                    Research Group</a>, as a <a href="https://www.pdsoros.org/meet-the-fellows/shomik-verma" target="_blank">PD Soros</a> and <a href="https://www.nsfgrfp.org/" target="_blank">NSF GRFP</a> Fellow.
                            </p>
                            <p> I am deeply fascinated by the future of energy and have worked in a variety of fields including 
                                electric vehicles, solar cells, fuel cells, hydrogen production, thermal energy storage, and thermophotovoltaics.
                                At MIT, my current projects are modeling how a <a href="pdfs/230103_HotP_Poster.pdf" target="_blank">thermal energy grid storage system</a> can best improve the reliability of renewables, designing a <a href="pdfs/230315_PITCHES_SVerma.pdf" target="_blank">next-generation power plant</a> based on hydrogen combustion and thermophotovoltaic power generation, and optimizing the performance of thermophotovoltaic cells using <a href="https://ieeexplore.ieee.org/abstract/document/9926355" target="_blank">first-principles calculations</a>.
                            </p>
                            <p>
                                I previously completed a 2-year <a
                                    href="https://pratt.duke.edu/about/news/verma-marshall" target="_blank"> Marshall
                                    Scholarship</a> for graduate study in the UK. For the first year of my Marshall, I
                                completed an MPhil in Materials Science at the University of Cambridge on <a
                                    href="/LSCs"> 3D printed
                                    LSCs</a> with <a href="https://labevans.co.uk/" target="_blank"> Prof. Rachel Evans</a>.
                                For
                                the second year, I completed another MPhil in Materials Science at Imperial College
                                London with <a href="https://wmd-group.github.io/" target="_blank"> Prof. Aron Walsh</a>,
                                focusing on high-throughput virtual screening of <a
                                    href="/HTVS">photon conversion molecules</a>.
                            </p>
                            <p>
                                Prior to this, in May 2019, I completed my <a
                                    href="https://www.youtube.com/watch?v=EJKcyCK_ZAs"
                                    target="_blank"> undergrad at Duke University</a>, where
                                I studied Mechanical Engineering with minors in Energy Engineering and Mathematics. During my undergrad, I worked on <a href="/solarh2"> hydrogen
                                production </a> from methane using concentrated solar power, and then worked on using
                                this hydrogen in a <a href="/fuelcell"> fuel cell </a> to power a prototype vehicle. The
                                car I helped build with Duke Electric Vehicles ended up breaking the <a
                                    href="https://pratt.duke.edu/about/news/duke-students-break-guinness-world-record-fuel-efficiency"
                                    target="_blank"> world record </a> for vehicle fuel efficiency. </p>
                            <p> After my bachelor's degree, I spent a summer at Georgia Tech investigating the
                                applications of <a href="/CSP"> concentrated solar power </a> for electricity
                                generation. This work was used in the design of a next-generation pilot plant through
                                the Department of Energy. </p>
                            <p> Over the course of my PhD, I hope to develop innovative technological solutions to the most
                                pressing energy problems. I am excited to learn from and work with a broad spectrum of talented people 
                                at MIT and beyond. Please feel free to reach out if you'd like to chat!
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={2}>
                        </Col>
                        <Col span={20}>
                            <object data='pdfs/cv.pdf' height="750" width="100%">
                                <p style={{ textAlign: "center" }}>Your browser doesn't support PDFs!<a
                                    href="pdfs/cv.pdf"> Download my CV</a> instead.</p>
                            </object>

                        </Col>
                        <Col span={2}>
                        </Col>
                    </Row>
                </>
                {/*<PDFViewer document = {{url: "pdfs/cv.pdf"}} scale={(window.innerWidth > 500) ? 1.5 : .6}/> */
                }

            </div>
        );
    }
}

export default NamePage;
