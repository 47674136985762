import "./index.scss";

import React from "react";
import ReactDOM from "react-dom";
// import ReactGA from "react-ga";
import App from "./components/App";

import * as serviceWorker from "./serviceWorker";

// const trackingId = "UA-164337188-1"; // Replace with your Google Analytics tracking ID
// ReactGA.initialize(trackingId);
// ReactGA.set({
//   page: 1
//   // any data that is relevant to the user session
//   // that you would like to track with google analytics
// })

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
