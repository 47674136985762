import React, {Component} from "react";
import "./App.scss";

class ResearchPage extends Component {

    public render() {
        return (
            <div className="Page">
                <h1>
                    Luminescent Solar Concentrators (LSCs)
                </h1>
                <p>
                    Project Title: End-to-end performance analysis of 3D printed luminescent solar concentrators
                </p>
                <p>
                    Project Dates: October 2019 - September 2020
                </p>
                <p>
                    Supervisor: <a href="https://www.msm.cam.ac.uk/people/evans" target="_blank"> Prof. Rachel Evans</a>
                </p>
                <p>
                    Collaborators: Anne Richter, Bolong Zhang, Mike Bennison
                </p>
                <p>
                    Location: <a href="https://labevans.co.uk/" target="_blank">Functional Photoactive Materials
                    Laboratory</a>, University of Cambridge
                </p>
                <h2> Project Summary: </h2>
                <p> In recent years, there has been increasing urgency to develop cheap, efficient solar devices.
                    However, most solar modules are large, bulky, and rectangular, making integration into the built
                    environment non-trivial. A promising technological solution that may help solve this problem is the
                    luminescent solar concentrator (LSC). A typical LSC is a plastic slab that absorbs sunlight and
                    re-emits light of a tailored wavelength towards its edges, where solar cells can be installed.
                    Because LSCs are colorful, semi-transparent, and modular, they hold great potential in reducing the
                    cost and barrier to entry of solar technologies. However, they suffer from significant optical
                    losses limiting their efficiency. Many novel device shapes have been proposed to improve light
                    transport pathways in LSCs, but these often rely on expensive or wasteful fabrication techniques.
                </p>
                <p>
                    A potential solution to this is 3D printing, which has gained immense traction as an alternative
                    manufacturing technology. 3D printing has many benefits including accessibility, rapid prototyping,
                    and fabrication of completely new designs. This study presents an end-to-end performance analysis
                    methodology to evaluate new LSC designs that can take advantage of this novel manufacturing
                    technology. This methodology is applied to preliminary 3D printed parts as a proof of concept that
                    can be applied to a wider variety of designs.</p>
                <p>
                    This study consists of 3 distinct steps: simulating the optical efficiency of the printed part, 3D
                    printing the part with luminescent filament, and characterizing its optical efficiency
                    experimentally.</p>
                <p>
                    Simulation of LSCs using ray tracing has been extensively investigated in the past; however, this
                    has been limited to conventional, rectangular LSCs. Analyzing the performance of alternative LSC
                    geometries requires developing a novel methodology for evaluating device efficiency, as well as
                    implementing parallelization techniques to reduce computation time for complex geometries.
                </p>
                <p>
                    Among the 3D printing technologies, fused deposition modeling (FDM) is the most accessible and
                    widely used, but it is not known for producing transparent parts usable for optical applications.
                    This thesis aims to solve some of the complications of FDM to develop a cheap, rapid, and accessible
                    methodology for printing efficient LSCs.
                </p>
                <p>
                    Similar to modeling, device characterization has been limited to rectangular LSCs, so a standardized
                    methodology for evaluating device efficiency has been developed, allowing for comparison between
                    different device shapes.
                </p>
                <p>
                    The simulated results of this study indicate 3D printed devices have the potential to offer a
                    twofold increase in efficiency over conventionally manufactured bulk devices. Actual 3D printed
                    devices were measured to have similar efficiency to bulk devices (within 1%), but extrapolating the
                    results to parts made with higher quality material also suggests some parts would have twice the
                    efficiency of their bulk counterparts. 3D printed parts may have additional benefits in improving
                    directionality of edge-emitted light, but this will have to be confirmed in future work.
                </p>
                <p>
                    Overall, 3D printing provides major benefits over conventional manufacturing techniques, as it
                    introduces rapid prototyping by allowing experimental iteration and model validation, allows
                    custom-built designs for easier integration into the built environment, and increases the optical
                    efficiency of devices by improving light transport pathways. With further development, this
                    technology can help make widespread solar adoption a reality.
                </p>
                <p> Full thesis available <a href="pdfs/Verma_MPhilThesis_Cambridge.pdf" target="_blank">here</a>! </p>

            </div>
        );
    }
}

export default ResearchPage;
