import React, { Component } from "react";
// import { Divider } from 'antd';
import "../App.scss";

class EGR201_Duke extends Component {

  public render() {
    return (
      <div className="Page">
        <h1> Teaching assistant for EGR 201 at Duke </h1>
        <h3> Aug 2016 - May 2019 </h3>
        <p>
          I was fortunate to be a teaching assistant during my undergraduate at Duke University for 6 semesters. I TA'd for EGR 201 (Statics and Mechanics of Materials), and I got to lead recitations sections to review concepts taught in lecture as well as work through some practice problems based on those concepts. I also held office hours, graded, and supervised labs including tension, torsion, and buckling tests.
        </p>
        
      </div>
    );
  }
}
export default EGR201_Duke;
