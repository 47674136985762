import React, {Component} from "react";
import "./App.scss";

class ResearchPage extends Component {

    public render() {
        return (
            <div className="Page">
                <h1>
                    Photon conversion molecules
                </h1>
                <p>
                    Project Title: High-throughput virtual screening of molecules for photon conversion
                </p>
                <p>
                    Project Dates: October 2020 - September 2021
                </p>
                <p>
                    Supervisors: <a href="https://www.imperial.ac.uk/people/a.walsh" target="_blank"> Prof. Aron
                    Walsh </a> and <a href="https://www.ucl.ac.uk/chemistry/people/david-scanlon" target="_blank"> Prof.
                    David Scanlon </a>
                </p>
                <p>
                    Collaborators: Jiali Li
                </p>
                <p>
                    Location: <a href="https://wmd-group.github.io/" target="_blank">Walsh Materials Design
                    Group</a>, Imperial College London
                </p>
                <h2> Project Summary: </h2>
                <p> Photovoltaics (PV) have emerged as a prominent technology to generate electricity from sunlight.
                    However, traditional single-junction PV cells such as silicon, thin film PV, and perovskites suffer
                    from an inherent efficiency limit of 33.7%. This is primarily due to two loss mechanisms:
                    sub-bandgap losses, where photons with energy below the bandgap of the PV cell cannot be utilized,
                    and thermalization losses, where photons with excess energy above the bandgap lose their excess
                    energy to heat. Photon conversion materials can help overcome the detailed-balance limit by
                    converting wavelengths of light into energies the solar cell can efficiently absorb. The two common
                    mechanisms for photon conversion are triplet-triplet annihilation (TTA) up-conversion and singlet
                    fission (SF) down-conversion. Several molecules have been shown to exhibit TTA or SF, but there
                    could be cheaper or less complex molecules previously overlooked that would be suitable. To identify
                    such chromophores, high-throughput virtual screening (HTVS) of large databases is required.
                </p>
                <p>
                    Both TTA and SF involve the singlet and triplet excited states of molecules, so knowing these
                    excited state energies is critical. The central issue to HTVS is that limited excited state
                    databases exist, and computational techniques for calculating excited state energies are
                    time-consuming. This thesis aims to solve this issue with various approaches. First, triplet excited
                    state energies are predicted with a machine learning (ML) model trained on a dataset of TD-DFT
                    energies generated with active learning (AL) to ensure the training set size is optimized. While
                    directly predicting energies with ML is fast, there are issues with accuracy and training time. The
                    second approach calibrates a high-throughput computational chemistry method called xTB-sTDA against
                    TD-DFT with ML. This ensures both high accuracy and low computation time. Finally, the third
                    approach applies xTB-ML to a large dataset, using AL to actively suggest candidate chromophores for
                    photon conversion.
                </p>
                <p> Full thesis available <a href="pdfs/Verma_MPhilThesis_Imperial.pdf" target="_blank">here</a>! </p>

            </div>
        );
    }
}

export default ResearchPage;
