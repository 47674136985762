import React, { Component } from "react";
import "./App.scss";

class SolarH2Page extends Component {

  public render() {
    return (
      <div className="Page">
      <h1>
		Solar Hydrogen Production
	</h1>
	<p>
		Project Title: Design and optimization of a high-temperature solar thermal collector for dry methane reforming
	</p>
	<p>
		Project Dates: January 2017 - May 2019
	</p>
	<p>
		Supervisor: <a href = "https://mems.duke.edu/faculty/nico-hotz" target = "_blank"> Dr. Nico Hotz </a>
	</p>
	<p>
		Location: <a href = "http://people.duke.edu/~nh66/" target = "_blank"> Thermodynamics and Sustainable Energy Laboratory</a>, Duke University
	</p>
	<p>
		Project Summary:
	</p>
	<p>
		Hydrogen is widely regarded as a fuel of the future, but its traditional generation process, steam reforming, is energy-intensive and utilizes harmful fossil fuels as chemical inputs. This study details the modeling and optimization of a dry methane reformer that uses biofuel-derived methane combined with carbon dioxide to produce hydrogen with heat input from concentrated sunlight. The multiphysics model utilizes a finite element mesh with coupled heat transfer, fluid flow, and chemical reaction equations to accurately model the reactor. Experimental data helps validate the computational model created, and also helps reduce the reaction order of the catalyst to reduce computation time. Then, various parameters within the reactor are optimized. This optimization includes materials analysis to select the best tube material for thermal insulation and optical analysis of the absorption coating to maximize absorber temperature. Following the multiphysics modeling and design optimization, steady state results are obtained for reactor operation. These results show the impact of both flowrate and concentration ratio on the conversion efficiency of reactants to products. The results show a concentration ratio of 20 is sufficient to achieve ~100% conversion efficiency for a reactant flowrate of 10 SCCM. This low concentration ratio is promising, and shows the feasibility of a small-scale, economical system for hydrogen generation through dry methane reforming.
	</p>
  <p> See <a href="/publications"> Publications </a> for the paper published on this research. </p>
  <img className = "researchImage" src = "images/3d_model.png"></img>
      </div>
    );
  }
}
export default SolarH2Page;
